<app-header-style-six></app-header-style-six>



<div class="page-title-area page-title-style-two item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">Anasayfa</a></li>
                <li>Hac Başvurusu</li>
            </ul>
            <h2>Hac Başvurusu</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="container application">
            <div class="tab-wrap application">

                <input type="radio" id="tab1" name="tabGroup1" class="tab" checked>
                <label for="tab1">Temel</label>

                <input type="radio" id="tab2" name="tabGroup1" class="tab" [disabled]="!this.checkForm(mainForm)">
                <label for="tab2">Kişisel Bilgiler</label>

                <input type="radio" id="tab3" name="tabGroup1" class="tab"
                    [disabled]="!this.checkForm(personalInformationForm)">
                <label for="tab3">Adres Bilgileri</label>

                <input type="radio" id="tab4" name="tabGroup1" class="tab" [disabled]="!this.checkForm(addressForm)">
                <label for="tab4">Pasaport Bilgileri</label>

                <input type="radio" id="tab5" name="tabGroup1" class="tab" [disabled]="!this.checkForm(passportForm)">
                <label for="tab5">Ek Bilgiler</label>

                <div class="tab__content">
                    <h3 application>Hac Başvurusu</h3>
                    <form [formGroup]="mainForm">
                        <fieldset class="row col md-12">
                            <div class="col-md-6">
                                <label for="roomType" class="form-label">Oda türü<label
                                        class="text-danger fs-5">*</label></label>
                                <select id="roomType" formControlName="roomTypeId" class="form-select">
                                    <option value="" selected disabled>Seçiniz</option>
                                    <option *ngFor="let item of roomTypes" [ngValue]="item.id">{{item.roomTypes}}
                                    </option>
                                </select>
                                <div *ngIf="mainForm.get('roomTypeId')?.invalid && (mainForm.get('roomTypeId').dirty || mainForm.get('roomTypeId').touched)"
                                    class="alert">
                                    Lütfen oda tipini seçiniz.
                                </div>


                            </div>
                            <div class="col-md-6">
                                <label for="closestAssociation" class="form-label">En Yakın Dernek<label
                                        class="text-danger fs-5">*</label></label>
                                <select id="closestAssociation" formControlName="closestAssociationId"
                                    class="form-select">
                                    <option value="" disabled selected>Seçiniz</option>
                                    <option *ngFor="let item of association" [ngValue]="item.id"> {{item.name}}
                                    </option>
                                </select>
                                <div *ngIf="mainForm.get('closestAssociationId')?.invalid && (mainForm.get('closestAssociationId')?.dirty || mainForm.get('closestAssociationId')?.touched)"
                                    class="alert">
                                    Lütfen en yakın derneği seçiniz.
                                </div>
                            </div>


                            <button type="submit" class="btn btn-primary" (click)="nextTab(0)"
                                [disabled]="!this.checkForm(mainForm)">
                                <div *ngIf="!this.checkForm(mainForm)">*İlerlemek için zorunlu alanları doldurunuz</div>
                                <div *ngIf="this.checkForm(mainForm)"> İlerle</div>

                            </button>

                        </fieldset>
                    </form>
                </div>

                <div class="tab__content personal">
                    <h3 application>Kişisel Bilgiler</h3>
                    <form class="row col md-12" [formGroup]="personalInformationForm">

                        <div class="col-md-6">
                            <label for="name" class="form-label">Ad<label class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" formControlName="name" id="name" placeholder="Ad"
                                required>
                            <div *ngIf="personalInformationForm.get('name')?.invalid && (personalInformationForm.get('name')?.dirty || personalInformationForm.get('name')?.touched)"
                                class="alert">
                                Lütfen adınızı giriniz.
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="surname" class="form-label">Soyad<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" formControlName="surname" id="surname"
                                placeholder="Soyad">
                            <div *ngIf="personalInformationForm.get('surname').invalid && (personalInformationForm.get('surname').dirty || personalInformationForm.get('surname').touched)"
                                class="alert">
                                Lütfen soyadınızı giriniz.
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="turkeyIdentificationNumber" class="form-label">TC Kimlik Numarası<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="number" class="form-control" formControlName="turkeyIdentificationNumber"
                                id="turkeyIdentificationNumber" [max]="99999999999" [min]="10000000000"
                                placeholder="xxxxxxxxxxx">
                            <div
                                *ngIf="personalInformationForm.get('turkeyIdentificationNumber').invalid && 
                                (personalInformationForm.get('turkeyIdentificationNumber').dirty || personalInformationForm.get('turkeyIdentificationNumber').touched)">

                                <div *ngIf="personalInformationForm.get('turkeyIdentificationNumber').hasError('required')"
                                    class="alert">
                                    Lütfen TC kimlik numaranızı giriniz.
                                </div>

                                <div *ngIf="personalInformationForm.get('turkeyIdentificationNumber').errors?.max || personalInformationForm.get('turkeyIdentificationNumber').errors?.min"
                                    class="alert">
                                    Lütfen 11 hane giriniz.
                                </div>

                            </div>

                        </div>
                        <div class="col-md-6">
                            <label for="genderId" class="form-label">Cinsiyet<label
                                    class="text-danger fs-5">*</label></label>
                            <select id="genderId" class="form-select" formControlName="genderId">
                                <option value="" disabled selected>Seçiniz</option>
                                <option *ngFor="let item of gender" [ngValue]="item.id">{{item.name}}</option>
                            </select>
                            <div *ngIf="personalInformationForm.get('genderId')?.invalid && (personalInformationForm.get('genderId')?.dirty || personalInformationForm.get('genderId')?.touched)"
                                class="alert">
                                Lütfen cinsiyetinizi seçiniz.
                            </div>

                        </div>
                        <div class="col-md-6">
                            <label for="dateOfBirth" class="form-label">Doğum Tarihi<label
                                    class="text-danger fs-5">*</label> <label class="alert"> ( Gün/Ay/Yıl )</label>
                            </label>
                            <input type="date" class="form-control" id="dateOfBirth" formControlName="dateOfBirth">
                            <div *ngIf="personalInformationForm.get('dateOfBirth').invalid && (personalInformationForm.get('dateOfBirth').dirty || personalInformationForm.get('dateOfBirth').touched)"
                                class="alert">

                                Lütfen doğum tarihinizi giriniz.

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="placeOfBirth" class="form-label">Doğum Yeri<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="placeOfBirth" formControlName="placeOfBirth"
                                placeholder="Doğum Yeri">
                            <div *ngIf="personalInformationForm.get('placeOfBirth').invalid && (personalInformationForm.get('placeOfBirth').dirty || personalInformationForm.get('placeOfBirth').touched)"
                                class="alert">

                                Lütfen doğum yerinizi giriniz.

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="swedenIdentificationNumber" class="form-label">İsveç Personnummer<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="swedenIdentificationNumber"
                                placeholder="İsveç Personnummer" formControlName="swedenIdentificationNumber">
                            <div *ngIf="personalInformationForm.get('swedenIdentificationNumber').invalid && (personalInformationForm.get('swedenIdentificationNumber').dirty || personalInformationForm.get('swedenIdentificationNumber').touched)"
                                class="alert">

                                Lütfen İsveç Personnummer giriniz.

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="maritalStatusId" class="form-label">Medeni Hali<label
                                    class="text-danger fs-5">*</label></label>
                            <select id="maritalStatusId" class="form-select" formControlName="maritalStatusId"
                                placeholder="Medeni Hali">
                                <option value="" disabled selected>Seçiniz</option>
                                <option *ngFor="let item of maritalStatus" [ngValue]="item.id">{{item.name}}</option>
                            </select>
                            <div *ngIf="personalInformationForm.get('maritalStatusId').invalid && (personalInformationForm.get('maritalStatusId').dirty || personalInformationForm.get('maritalStatusId').touched)"
                                class="alert">

                                Lütfen medeni halinizi seçiniz.

                            </div>

                        </div>
                        <div class="col-md-6">
                            <label for="nationality" class="form-label">Uyruk<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="nationality" formControlName="nationality"
                                placeholder="Uyruk">
                            <div *ngIf="personalInformationForm.get('nationality').invalid && (personalInformationForm.get('nationality').dirty || personalInformationForm.get('nationality').touched)"
                                class="alert">

                                Lütfen uyruğunuzu giriniz.

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="phoneNumber" class="form-label">Telefon Numarası<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="phoneNumber" formControlName="phoneNumber"
                                placeholder="Telefon Numarası">
                            <div *ngIf="personalInformationForm.get('phoneNumber').invalid && (personalInformationForm.get('phoneNumber').dirty || personalInformationForm.get('phoneNumber').touched)"
                                class="alert">

                                Lütfen telefon numaranızı giriniz.

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="motherName" class="form-label">Anne Adı<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="motherName" formControlName="motherName"
                                placeholder="Anne Adı">
                            <div *ngIf="personalInformationForm.get('motherName').invalid && (personalInformationForm.get('motherName').dirty || personalInformationForm.get('motherName').touched)"
                                class="alert">

                                Lütfen anne adınızı giriniz.

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="fatherName" class="form-label">Baba Adı<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="fatherName" formControlName="fatherName"
                                placeholder="Baba Adı">
                            <div *ngIf="personalInformationForm.get('fatherName').invalid && (personalInformationForm.get('fatherName').dirty || personalInformationForm.get('fatherName').touched)"
                                class="alert">

                                Lütfen baba adınızı giriniz.

                            </div>
                        </div>
                        <div class="col-md-12">
                            <label for="headshotPicture" class="form-label">Vesikalık Fotoğraf</label>
                            <input class="form-control" type="file" id="headshotPicture"
                                (change)="uploadFileEvent($event,'head')">
                        </div>

                        <button type="submit" class="btn btn-primary" (click)="nextTab(1)"
                            [disabled]="!this.checkForm(personalInformationForm)">
                            <div *ngIf="!this.checkForm(personalInformationForm)">*İlerlemek için zorunlu alanları
                                doldurunuz</div>
                            <div *ngIf="this.checkForm(personalInformationForm)"> İlerle</div>
                        </button>
                    </form>

                </div>

                <div class="tab__content">
                    <h3 application>Adres Bilgileri</h3>
                    <form class="row col md-12" [formGroup]="addressForm">
                        <div class="col-md-12">
                            <label for="address" class="form-label">Adres<label class="text-danger fs-5">*</label><span
                                    style="font-size: 0.8em; font-weight: bold; opacity: 0.7;">- Adress</span></label>
                            <textarea class="form-control" id="address" rows="3" formControlName="address"
                                placeholder="Adres"></textarea>
                            <div *ngIf="addressForm.get('address').invalid && (addressForm.get('address').dirty || addressForm.get('address').touched)"
                                class="alert">

                                Lütfen adresinizi giriniz.

                            </div>
                        </div>
                        <div class="col-md-4">
                            <label for="city" class="form-label">Posta Kodu<label
                                    class="text-danger fs-5">*</label><span
                                    style="font-size: 0.8em; font-weight: bold; opacity: 0.7;">-
                                    Postnummer</span></label>
                            <input type="text" class="form-control" id="city" formControlName="postCode"
                                placeholder="Posta Kodu">
                            <div *ngIf="addressForm.get('postCode').invalid && (addressForm.get('postCode').dirty || addressForm.get('postCode').touched)"
                                class="alert">

                                Lütfen posta kodunu giriniz.

                            </div>
                        </div>
                        <div class="col-md-4">
                            <label for="city" class="form-label">Şehir<label class="text-danger fs-5">*</label><span
                                    style="font-size: 0.8em; font-weight: bold; opacity: 0.7;">- Stad</span></label>
                            <input type="text" class="form-control" id="city" formControlName="city"
                                placeholder="Şehir">
                            <div *ngIf="addressForm.get('city').invalid && (addressForm.get('city').dirty || addressForm.get('city').touched)"
                                class="alert">

                                Lütfen şehrinizi giriniz.

                            </div>
                        </div>
                        <div class="col-md-4">
                            <label for="country" class="form-label">Ülke<label class="text-danger fs-5">*</label><span
                                    style="font-size: 0.8em; font-weight: bold; opacity: 0.7;">- Land</span></label>
                            <input type="text" class="form-control" id="country" formControlName="country"
                                placeholder="Ülke">
                            <div *ngIf="addressForm.get('country').invalid && (addressForm.get('country').dirty || addressForm.get('country').touched)"
                                class="alert">

                                Lütfen ülkenizi giriniz.

                            </div>
                        </div>


                        <button type="submit" class="btn btn-primary" (click)="nextTab(2)"
                            [disabled]="!this.checkForm(addressForm)">
                            <div *ngIf="!this.checkForm(addressForm)">*İlerlemek için zorunlu alanları
                                doldurunuz</div>
                            <div *ngIf="this.checkForm(addressForm)"> İlerle</div>
                        </button>
                    </form>
                </div>

                <div class="tab__content">
                    <h3 application>Pasaport Bilgileri</h3>
                    <form class="row col md-12" [formGroup]="passportForm">
                        <div class="col-md-6">
                            <label for="passportGivenPlace" class="form-label">Pasaport Veriliş Yeri<label
                                    class="text-danger fs-5">*</label> </label>
                            <input type="text" class="form-control" id="passportGivenPlace"
                                placeholder="Pasaport Veriliş Yeri" formControlName="passportGivenPlace">
                            <div *ngIf="passportForm.get('passportGivenPlace').invalid && (passportForm.get('passportGivenPlace').dirty || passportForm.get('passportGivenPlace').touched)"
                                class="alert">

                                Lütfen pasaport veriliş yerini giriniz.

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="passportNumber" class="form-label">Pasaport Numarası<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="passportNumber" formControlName="passportNumber"
                                placeholder="Pasaport Numarası" style="text-transform: uppercase !important;">
                            <div *ngIf="passportForm.get('passportNumber').invalid && (passportForm.get('passportNumber').dirty || passportForm.get('passportNumber').touched)"
                                class="alert">

                                Lütfen pasaport numaranınzı giriniz.

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="passportGivenDate" class="form-label">Pasaport Veriliş Tarihi<label
                                    class="text-danger fs-5">*</label> <label class="alert"> ( Gün/Ay/Yıl
                                    )</label></label>
                            <input type="date" class="form-control" id="passportGivenDate"
                                placeholder="Pasaport Veriliş Tarihi" formControlName="passportGivenDate">
                            <div *ngIf="passportForm.get('passportGivenDate').invalid && (passportForm.get('passportGivenDate').dirty || passportForm.get('passportGivenDate').touched)"
                                class="alert">

                                Lütfen pasaport veriliş tarihini giriniz.

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="passportExpirationDate" class="form-label">Pasaport Son Kullanma Tarihi<label
                                    class="text-danger fs-5">*</label> <label class="alert"> ( Gün/Ay/Yıl
                                    )</label></label>
                            <input type="date" class="form-control" id="passportExpirationDate"
                                placeholder="Pasaport Son Kullanma Tarihi" formControlName="passportExpirationDate"
                                (change)="checkPassportExpirationDate()">
                            <div  *ngIf="passportForm.get('passportExpirationDate').invalid && 
                            (passportForm.get('passportExpirationDate').dirty || passportForm.get('passportExpirationDate').touched)">

                                <div *ngIf="passportForm.get('passportExpirationDate').hasError('required')"
                                    class="alert">
                                    Lütfen pasaport son kullanma tarihini giriniz.
                                </div>
                                <div *ngIf="passportForm.get('passportExpirationDate').hasError('expired')"
                                    class="alert">
                                    Pasaport tarihinizin süresi dolmuş.
                                </div>
                                <div *ngIf="passportForm.get('passportExpirationDate').hasError('tooSoon')"
                                    class="alert">
                                    Pasaport tarihi 8 aydan kısa olmamalıdır.
                                </div>


                            </div>
                        </div>
                        <div class="col-md-12">
                            <label for="passportPicture" class="form-label">Pasaport Fotoğrafı </label>
                            <input type="file" class="form-control" id="passportPicture"
                                (change)="uploadFileEvent($event,'pass')">
                        </div>

                        <button type="submit" class="btn btn-primary" (click)="nextTab(3)"
                            [disabled]="!this.checkForm(passportForm)">
                            <div *ngIf="!this.checkForm(passportForm)">*İlerlemek için zorunlu alanları
                                doldurunuz</div>
                            <div *ngIf="this.checkForm(passportForm)"> İlerle</div>
                        </button>
                    </form>
                </div>

                <div class="tab__content">
                    <h3 application>Ek Bilgiler</h3>
                    <form class="row col md-12" [formGroup]="otherForm">
                        <div class="col-md-6">
                            <label for="departureAirportId" class="form-label">Gidiş Havaalanı<label
                                    class="text-danger fs-5">*</label></label>
                            <select id="departureAirportId" class="form-select" formControlName="departureAirportId"
                                placeholder="Gidiş Havaalanı">
                                <option value="" disabled selected>Seçiniz</option>
                                <option *ngFor="let item of airports" [ngValue]="item.id">{{item.name}}</option>
                            </select>
                            <div *ngIf="otherForm.get('departureAirportId')?.invalid && (otherForm.get('departureAirportId')?.dirty || otherForm.get('departureAirportId')?.touched)"
                                class="alert">

                                Lütfen gidiş havaalanı seçiniz.

                            </div>

                        </div>
                        <div class="col-md-6">
                            <label for="landingAirportId" class="form-label">Dönüş Havaalanı<label
                                    class="text-danger fs-5">*</label></label>
                            <select id="landingAirportId" class="form-select" formControlName="landingAirportId"
                                placeholder="Dönüş Havaalanı">
                                <option value="" disabled selected>Seçiniz</option>
                                <option *ngFor="let item of airports" [ngValue]="item.id">{{item.name}}</option>
                            </select>
                            <div *ngIf="otherForm.get('landingAirportId')?.invalid && (otherForm.get('landingAirportId')?.dirty || otherForm.get('landingAirportId')?.touched)"
                                class="alert">

                                Lütfen dönüş havaalanı seçiniz.

                            </div>

                        </div>
                        <div class="col-md-12">
                            <label for="explanation" class="form-label">Açıklama/ Not</label>
                            <textarea class="form-control" id="explanation" rows="3" placeholder="Açıklama/ Not"
                                formControlName="explanation"></textarea>
                        </div>

                        <button
                            [disabled]="isLoading || !mainForm.valid || !personalInformationForm.valid || !addressForm.valid || !passportForm.valid || !otherForm.valid"
                            (click)="saveForm() " class="btn btn-primary">
                            <span *ngIf="!isLoading">Gönder</span>
                            <span *ngIf="isLoading">
                                <i class="fa fa-spinner fa-spin"></i> Gönderiliyor...
                            </span>
                            <div *ngIf="!isLoading" routerLink=""></div>
                        </button>
                    </form>


                </div>

            </div>

        </div>
    </div>
</div>